import { QueryClient } from '@tanstack/react-query';
import { LoginSearchPramas } from '@wnc/ui/src/constants';
import { Axios } from 'axios';
import { ApiConfig, config } from '../../config';
import { SentryService } from '../../sentry/sentry';

export class ApiError extends Error {
  constructor(message: string) {
    super();
    this.name = 'ApiError';
    this.message = message;
  }
}

export const addMiddleware = (
  axiosInstance: Axios,
  apiConfig: ApiConfig,
  queryClient: QueryClient,
  sentry: SentryService
) => {
  const fullLocation = `/pmi${window.location.pathname}`;
  const encodedLocation = encodeURIComponent(fullLocation);
  // Logging Interceptors
  axiosInstance.interceptors.request.use((config) => {
    const { method, url, headers, data } = config;
    if (apiConfig.useMock) {
      if (config.data) {
        console.info('REQUEST', method, url, headers.Authorization, data);
      } else {
        console.info('REQUEST', method, url, headers.Authorization);
      }
    }
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      if (apiConfig.useMock) {
        console.info('RESPONSE', response);
      }
      return response;
    },
    (error) => {
      if (apiConfig.useMock)
        console.info('ERROR', error.response.status, error.config.method, error.config.url, { ...error });
      return Promise.reject(error);
    }
  );

  // Auth header interceptor
  axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  // Error interceptor
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error: any) => {
      const message =
        error.response?.data?.message ||
        error.response?.data?.err_message ||
        error.response?.data?.details ||
        error.response?.data?.detail ||
        (error.code === 'ECONNABORTED' &&
          'Request timeout: The server took too long to respond. Please try again later.') ||
        'Unknown error message';

      error.message = message;
      const url = error.config?.url?.replace(apiConfig.baseUrl, '');
      const regex = /\/(?=[a-zA-Z]*\d)(?=[\d-]*[a-zA-Z])[\w-]+(?=\/|$)/g;
      const replacedUrl = url.replace(regex, '/<id>');
      const errorLogMessage = `${(error.config?.method as string)?.toUpperCase()} ${replacedUrl} ${
        error.response?.status
      } - ${message}`;
      sentry.logError(new ApiError(errorLogMessage));

      if (error.response?.status === 401) {
        localStorage.removeItem('token');
        let fullLocation = encodedLocation;
        window.location.replace(
          `${config.authUrl}/login?${LoginSearchPramas.Message}=${error.message}&${LoginSearchPramas.Severity}=error&${
            LoginSearchPramas.Url
          }=${encodeURIComponent(fullLocation)}`
        );
        queryClient.clear();
      }

      return Promise.reject(error);
    }
  );
};
